import React, { useState, useEffect } from "react"
import LogoPro from "../../assets/logo-taptapcap-pro.svg"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Link } from "gatsby"
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import "../../components/layout.css";

function About(props) {

  const location = useLocation();
  const [version, setVersion] = useState();

  useEffect(() => {
    setVersion(queryString.parse(location.search).version);
  }, [location, location.search])
  return (
    <div>

      <GatsbySeo nofollow={true} />

      <header><LogoPro className="mx-auto mt-6" /></header>

      <h1 className="text-center leading-6 p-2"><span className="text-yellow">TapTapCap Pro is free for a limited time.</span> Because we could all use a little help this year.</h1>

      <h6>Help</h6>
      <ul className="links">
        <li>
          <a href={`mailto:support@taptapcap.com?&body=%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0AVersion: ${version} %0D%0A`}>Email support@taptapcap.com</a>
        </li>
        <li><Link to="/support/">FAQ</Link></li>
        <li>
          <a href="replay-tutorial">Replay Tutorial</a>
        </li>
      </ul>

      <h6>About app</h6>
      <ul className="links">
        <li>Version {`${version}`}</li>
      </ul>

      <h6>Visit us</h6>
      <ul className="links">
        <li><a href="https://www.tiktok.com/@taptapcap" target="_blank"  rel="noreferrer">TikTok</a></li>
        <li><a href="https://www.instagram.com/taptapcap/" target="_blank"  rel="noreferrer">Instagram</a></li>
      </ul>

      <h6>Privacy and Terms</h6>
      <ul className="links">
        <li><a href="https://www.iubenda.com/privacy-policy/13230778" target="_blank"  rel="noreferrer" className="underline">Privacy Policy</a></li>
        <li><a href="/terms-ios/" target="_blank" rel="noreferrer" className="underline">Terms and Conditions</a></li>
      </ul>

      <p className="mt-32 text-center text-xs">© {new Date().getFullYear()} Captioned • Made with ❤️ in CA & VA</p>

    </div>
  )

}

export default About
